import React from "react";
import { Container, Button } from "react-bootstrap";
import res from "../assets/img/Resume.pdf";

export const Resume = () => {
  return (
    <section className="resume" id="resume">
      <Container>
        <div className="resume-bx">
          <h2>Resume</h2>
          <p>
            Click the button below to download my Resume to your machine! If you
            would like to view it my LinkedIn profile has a regular view option
            available!
          </p>
          <Button href={res} download="Resume">
            Download
          </Button>
        </div>
      </Container>
    </section>
  );
};
