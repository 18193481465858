import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCards";
import COFO from "../assets/img/COFO.png";
import Template from "../assets/img/Icon_WHITE.png";
import OtherTemplate from "../assets/img/Icon_BLUE.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Customer Support Form",
      description:
        "This project was completed in order to learn how ReactJS can be used to interact with a Backend application developed in ExpressJS. The frontend was developed using ReactJS, or HTML, CSS, and JavaScript. The Backend was developed using the ExpressJS library to create a server that the Frontend can make HTTP requests to in order to interact with a Cloud MySQL Database that stores Support Tickets submitted by this form.",
      imgUrl: Template,
      sourceURL: "https://github.com/BrockBloomquist/CustomerSupportApp",
    },
    {
      title: "Customer Support Merchant Side",
      description:
        "This project was completed in order to create another way to interact with the Cloud Database that exists in AWS that users can submit their support tickets through. This Frontend application was developed in ReactJS and makes HTTP requests to the Cloud Database to retrieve tickets, pseudo-delete tickets and also permanently delete them.",
      imgUrl: OtherTemplate,
      sourceURL: "https://github.com/BrockBloomquist/Customer-Support-Merchant",
    },
    {
      title: "Ecommerce Ruby Application",
      description:
        "This Ecommerce app with both Client and Admin facing sides was built/in development.",
      imgUrl: Template,
      sourceURL: "https://github.com/BrockBloomquist/Ecommerce-App-Ruby",
    },
    {
      title: "AlphaBlog",
      description:
        "This Blog web application was devleoped in Ruby on Rails in order to learn Rails 7 and Bootstrap 5.3.",
      imgUrl: OtherTemplate,
      sourceURL: "https://github.com/BrockBloomquist/AlphaBlog",
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__bounce" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>
                    In order to further my experience with Web Applications, I
                    have worked on the following projects in different tech
                    stacks. These tech stacks range from ReactJS, ExpressJS,
                    Ruby on Rails and many more. Some incorporte DevOps such as
                    AWS RDBMS instance using MySQL and other library components!
                  </p>
                </div>
              )}
            </TrackVisibility>
            <Row>
              {projects.map((project, index) => {
                return <ProjectCard key={index} {...project} />; // maps projects array 3-tuple to ProjectCard component and displays on the first tab
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
