import { Col, Button } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, sourceURL }) => {
  return (
    <Col sm={6} md={4}>
      <Button
        href={sourceURL}
        style={{
          backgroundColor: "transparent",
          border: "transparent",
        }}
      >
        <div className="proj-imgbx">
          <img src={imgUrl} />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span
              style={{
                marginBottom: "10px",
              }}
            >
              {description}
            </span>
          </div>
        </div>
      </Button>
    </Col>
  );
};
