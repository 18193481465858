import { Container, Row, Col } from "react-bootstrap";
import Me from "../me.jpg";
import { useState, useEffect } from "react";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ["Software Engineer", "College Graduate"];
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(150 - Math.random() * 100);
  const period = 2000;
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);
    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(250);
    }
  };

  return (
    <section className="banner" id="home">
      <Container className="align-items-center">
        <Row>
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Welcome to my Portfolio!</span>
            <h1>{`Hi, I'm Brock Bloomquist! `}</h1>
            <h2>
              <span className="wrap">{text}</span>
            </h2>
          </Col>
          <img src={Me} alt="me" />
          <p>
            Welcome to my Portfolio! Currently I am developing my hard-skills
            and furthering my understanding on Software Engineering with a
            multitude of frameworks and technologies.
          </p>
        </Row>
      </Container>
    </section>
  );
};
