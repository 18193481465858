import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Experience() {
  return (
    <>
      <section className="experience" id="experience">
        <div className="experience">
          <div className="experience-bx">
            <h2>Experience</h2>
            <VerticalTimeline lineColor="#fff">
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="Aug. 2023 - Dec. 2023"
                iconStyle={{ background: "#1700bb" }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    QA Software Engineer Intern
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    SkillsEngine
                  </h4>
                  <h5>Remote</h5>
                  <p>
                    Skills:<span className="bubble">Javascript</span>
                    <span className="bubble">Manual/Exploratory Testing</span>
                    <span className="bubble">Automated Testing</span>
                    <span className="bubble">CypressJS</span>
                    <span className="bubble">Ruby on Rails</span>
                    <span className="bubble">Debugging</span>
                    <span className="bubble">QaseIO</span>
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="May 2023 - Jul. 2023"
                iconStyle={{ background: "#1700bb" }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    IT Analyst
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    SKY IT Solutions
                  </h4>
                  <h5>Weslaco, TX</h5>
                  <p>
                    Skills:<span className="bubble">Cable Management</span>
                    <span className="bubble">Router Installation</span>
                    <span className="bubble">Ethernet Port Installation</span>
                    <span className="bubble">Server Infrastructure</span>
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="Aug. 2019 - May 2023"
                iconStyle={{ background: "#aa367c" }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    Southwestern University
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    Bachelors Degree in Computer Science
                  </h4>
                  <h5>Georgetown, TX</h5>
                  <p>
                    Skills:<span className="bubble">Fullstack Development</span>
                    <span className="bubble">Java</span>
                    <span className="bubble">C</span>
                    <span className="bubble">C++</span>
                    <span className="bubble">Python</span>
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="Aug. 2015 - May. 2019"
                iconStyle={{ background: "#aa367c" }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    Weslaco High School
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    High School Diploma
                  </h4>
                  <h5>Weslaco, TX</h5>
                </div>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      </section>
    </>
  );
}

export default Experience;
