import { Container, Row, Col } from "react-bootstrap";
import LOGO from "../assets/img/Icon_WHITE.png";
export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={LOGO} alt="logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end"></Col>
        </Row>
      </Container>
    </footer>
  );
};
